import { useEffect, useState } from "react";

const useWidth = () => {
  const defaultWidth = window.innerWidth;
  const [currentWidth, setCurrentWidth] = useState(defaultWidth);
  const [burgerShow, setBurgerShow] = useState();
  const [mainSolution, setMainSolution] = useState();

  const resize = () => {
    setCurrentWidth(window.innerWidth);
  };
  window.addEventListener("resize", resize);

  useEffect(() => {
    if (currentWidth <= 1200) {
      setBurgerShow(true);
      setMainSolution(true);
    } else {
      setBurgerShow(false);
      setMainSolution(false);
    }
  }, [currentWidth]);
  return burgerShow;
  return mainSolution;
};

export default useWidth;
