/*eslint-disable*/

import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import useObserver from "../useHooks/useObserver";
import "../css/his.css";

import Footer from "../components/Footer";
import CompanyNav from "../components/CompanyNav";

function His() {
  const ioRef = useObserver();

  return (
    <div className="hisContainer">
      <div className="hisHeader header" ref={ioRef}>
        <h2>연혁</h2>
      </div>
      <CompanyNav />

      <div className="history">
        <h2>
          연혁
          <p>DI Solution</p>
        </h2>

        <div className="imgBox"></div>
        <p className="hisCopy">
          DI Solution은 고객과 우리의 가치를 위해
          <span>도전과 성취의 길을 걸어갑니다.</span>
        </p>

        <div className="hisBox">
          <div>
            <p>2022</p>
            <span>
              본사 확장이전 <br />
              LG U+ 비즈니스 플랜 부문 1st 파트너 계약 체결
            </span>
          </div>
          <div>
            <p>2021</p>
            <span>
              동국산업 투자 유치 <br />
              AI 특허 등록 <br />
              AI 데이터구축 사업 2건 계약 체결
            </span>
          </div>
          <div>
            <p>2020</p>
            <span>AI바우처사업 공급업체 등록</span>
          </div>
          <div>
            <p>2019</p>
            <span>
              기업부설연구소 등록 <br />
              국제영화제 파트너 체결
            </span>
          </div>
          <div>
            <p>2018</p>
            <span>
              프론티어 벤처기업 선정 <br />
              LG U+ 파트너 체결
            </span>
          </div>
          <div>
            <p>2017</p>
            <span>(주)디아이솔루션 설립</span>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default His;
