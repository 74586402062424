/*eslint-disable*/

import React from "react";
import "../css/company.css";

function Company() {

  window.addEventListener("scroll", function () {
    this.scrollY > 200
      ? $(".topBtn").addClass("on")
      : $(".topBtn").removeClass("on");
  });

  const moveTop = () => {
    $("html, body").animate({ scrollTop: 0 });
  };

  return <div className="topBtn" onClick={moveTop}></div>;
}

export default Company;
