/*eslint-disable*/

import React from "react";
import "../css/footer.css";

import Flogo from "../images/footer-logo.png";

function Footer() {
  return (
    <div className="footerContainer">
      <div className="subfooterCon">
        <p>COMPANY INFO</p>
        <div className="infoCon">
          <p>
            <span>대표</span>남태우
          </p>
          <p>
            <span>주소</span>부산광역시 동래구 온천장로 109 불이빌딩 3층
          </p>
          <p>
            <span>사업자등록번호</span>234-88-00839
          </p>
          <p>
            <span>TEL</span>051-717-2503
          </p>
          <p>
            <span>FAX</span>070-8282-0624
          </p>
        </div>
        <img src={Flogo} alt="logo" />
      </div>
    </div>
  );
}

export default Footer;
