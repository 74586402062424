/*eslint-disable*/

import React from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";

function SolutionNav() {
  const location = useLocation();

  return (
    <div id="lnb">
      <div>
        <Link to="/solution/1" 
          className={location.pathname === "/solution/1" ? "on" : ""}>
          디지털트윈 안전 관리 플랫폼</Link>
        <div className={location.pathname === "/solution/1" ? "on" : ""} />
      </div>
      <div>
        <Link to="/solution/2" 
         className={location.pathname === "/solution/2" ? "on" : ""}>
          AI 딥러닝 기반 영상 처리 솔루션
        </Link>
        <div className={location.pathname === "/solution/2" ? "on" : ""} />
      </div>
      <div>
        <Link to="/solution/3"
          className={location.pathname === "/solution/3" ? "on" : ""}>
          자동 번역 솔루션</Link>
        <div className={location.pathname === "/solution/3" ? "on" : ""} />
      </div>
      <div>
        <Link to="/solution/4"
          className={location.pathname === "/solution/4" ? "on" : ""}>
          항만 모니터링 및 분석 시스템</Link>
        <div className={location.pathname === "/solution/4" ? "on" : ""} />
      </div>
      <div>
        <Link to="/solution/5"
          className={location.pathname === "/solution/5" ? "on" : ""}>
          클라우드형 빅데이터 분석 플랫폼</Link>
        <div className={location.pathname === "/solution/5" ? "on" : ""} />
      </div>
      <div>
        <Link to="/solution/6"
          className={location.pathname === "/solution/6" ? "on" : ""}>
          환경 모니터링 시스템</Link>
        <div className={location.pathname === "/solution/6" ? "on" : ""} />
      </div>
    </div>
  );
}

export default SolutionNav;
