/*eslint-disable*/

import React from "react";
import Footer from "../components/Footer";

import { Link, Route, Switch } from "react-router-dom";
import useObserver from "../useHooks/useObserver";

import "../css/info.css";
import CompanyNav from "../components/CompanyNav";

function Info() {
  const ioRef = useObserver();

  return (
    <div className="InfoContainer">
      <div className="infoHeader header" ref={ioRef}>
        <h2>
          회사소개
        </h2>
      </div>

      <CompanyNav />

      <div className="company">
        <h2>DI SOLUTION</h2>
        <p>
          오늘날의 산업은 데이터 자본(DataCapital)의 현실을 깨닫기 시작했습니다.
          <br />
          데이터는 단순히 과거 일어난 일의 기록이 아니라 새로운 디지털 제품 및
          서비스를 개발하기 위한 원료라고 할 수 있습니다. <br />
          디아이 솔루션은 이런 데이터를 담는 클라우드 시스템, 4차 산업혁명을
          이끌어갈 빅데이터 및 딥러닝까지 <br />
          데이터와 관련된 전문가 집단이며 데이터에 대한 새로운 가치를 창출해
          내겠습니다. <br />
          <br />
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Info;
