/*eslint-disable*/

import React from "react";
import Footer from "../components/Footer";

import { Link, Route, Switch } from "react-router-dom";

import "../css/Barrot.css";

import InfoImg from "../images/barrotInfo.png";
import MInfoImg from "../images/MbarrotInfo.png";

import MoM from "../images/barrotIcon1.png";
import Intuitive from "../images/barrotIcon2.png";

import Fun1 from "../images/barrotFun1.png";
import Fun2 from "../images/barrotFun2.png";
import Fun3 from "../images/barrotFun3.png";

import Modal from "react-modal";
import { useState } from "react";
import useObserver from "../useHooks/useObserver";
import SolutionNav from "../components/SolutionNav";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Barrot() {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState("");
  const ioRef = useObserver();

  function toggleModal(e) {
    setIsOpen(!isOpen);
    if (isOpen) {
      setOpenModal(e.target.id);
    } else {
      setOpenModal("");
    }
  }

  return (
    <div className="barrotContainer">
      <div className="barrotHeader header" ref={ioRef}>
        <h2>
          자동 번역 솔루션
          <p>
            실시간으로 화자의 발화 시작/종료 시점을 인식하여 문자로 변환하고,
            자동 회의록 작성, 이메일 전송 등 다양한 편의 기능을 제공합니다.
          </p>
        </h2>
      </div>
      <SolutionNav />

      <div className="barrotCon Contents">
        <div className="barrotInfoTop">
          <img src={MInfoImg} alt="barrot info img" />
        </div>

        <div className="barrotInfoBottom">
          <h3>Global Meeting을 위한 자동 번역 솔루션</h3>
          <div className="line"></div>
          <p className="infoText">
            화자의 <span>발화시점을 인식</span>하여 <span>자동으로 녹음</span>이
            시작되고,
            <br />
            <span>실시간</span>으로 녹음된 음성이 인공지능 모델을 통해{" "}
            <span>텍스트로 변환</span>됩니다. <br />
            <br />
            변환된 텍스트는 다시 인공지능 모델을 통해 설정된{" "}
            <span>상대의 언어로 번역</span>됩니다.
          </p>
        </div>

        <div className="barrotIcoSec">
          <div>
            <img src={MoM} alt="MoM Icon" />
            <p>
              번거로운 회의록 작성 문제 해결
              <br />
              <span>
                설정을 통하여 회의의 음성 녹음 파일(.wav) 및 속기 파일(.txt)을
                저장할 수 있으며 저장된 파일은 회의 종료시 이메일로 바로 전송
                가능합니다.
              </span>
            </p>
          </div>
          <div>
            <img src={Intuitive} alt="UI Icon" />
            <p>
              직관적인 UI
              <br />
              <span>
                아이콘 기반의 직관적인 UI로 누구나 쉽게 사용할 수 있으며
                관리자는 발화시간 제한, 발화인식 dB 등 전문적인 설정이
                가능합니다.
              </span>
            </p>
          </div>
        </div>

        <div className="function">
          <h3>주요기능</h3>
          <div className="funCon1">
            <div>
              <p>01</p>
              <p>언어 설정</p>
              <span>Host언어와 Guest언어를 각각 설정합니다.</span>
            </div>
            <div>
              <img src={Fun1} alt="" id="Fun1" onClick={toggleModal} />
              <Modal
                isOpen={"Fun1" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Fun1} alt="" onClick={toggleModal} />
              </Modal>
            </div>
          </div>
          <div className="funCon2">
            <div>
              <img src={Fun2} alt="" />
            </div>
            <div>
              <p>02</p>
              <p>녹음 시작</p>
              <span>
                화자의 발화가 시작되면 높은 dB(데시벨)의 소리를 인식하여 언어를
                선택하고 자동으로 녹음이 시작됩니다.
              </span>
            </div>
          </div>
          <div className="funCon3">
            <div>
              <p>03</p>
              <p>번역 시작</p>
              <span>
                녹음되는 음성에 대한 STT(Speech To Text) 및 번역이 실행됩니다.
              </span>
            </div>
            <div>
              <img src={Fun3} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Barrot;
