/*eslint-disable*/

import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import useObserver from "../useHooks/useObserver";
import "../css/Viva.css";

import Footer from "../components/Footer";
import CompanyNav from "../components/CompanyNav";

import value from "../images/value.png";
import harmony from "../images/harmony.png";
import achieve from "../images/achieve.png";
import evo from "../images/evo.png";

function Viva() {
  const ioRef = useObserver();

  return (
    <div className="vivaContainer">
      <div className="vivaHeader header" ref={ioRef}>
        <h2>비전 & 핵심가치</h2>
      </div>

      <CompanyNav />

      <div className="subvision">
        <h2>VISION</h2>
        <p>고객과 "스마트 데이터 인더스트리"의 가치를 공유하는 기업</p>
      </div>
      <div className="submission">
        <h2>MISSION</h2>
        <p>
          영남권 "스마트 데이터 인더스트리" B2G2B 개척 및 메이저 마켓 진입 발판
          마련
        </p>
      </div>
      <div className="subvalue">
        <h2>CORE VALUE</h2>

        <div className="valueIco">
          <div className="harmony">
            <div>
              <img src={harmony} alt="corevalue1" className="valueImg" />
            </div>
            <p>고객, 회사, 인재의</p>
            <p>Harmony</p>
          </div>

          <span className="valueLine"></span>

          <div className="achieve">
            <div>
              <img src={achieve} alt="corevalue2" className="valueImg" />
            </div>
            <p>각자의 가치, 공동의 가치를</p>
            <p>Achievement</p>
          </div>

          <span className="valueLine"></span>

          <div className="evo">
            <div>
              <img src={evo} alt="corevalue3" className="valueImg" />
            </div>
            <p>모두의 미래를 위한</p>
            <p>Evolution</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Viva;
