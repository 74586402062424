/*eslint-disable*/

import React, { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { HeaderContext } from "../App";

function useObserver() {
  const headerRef = useContext(HeaderContext);
  const ioRef = useRef(null);
  const options = {
    root: document.querySelector("#scrollArea"),
    rootMargin: "0px",
    threshold: 0,
  };

  const intersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        headerRef.headerRef.current.classList.add("pages");
        headerRef.headerRef.current.classList.remove("main");
      } else {
        headerRef.headerRef.current.classList.remove("pages");
        headerRef.headerRef.current.classList.add("main");
      }
    });
  };

  useEffect(() => {
    if (!ioRef) return;
    let io = new IntersectionObserver(intersection, options);
    io.observe(ioRef.current);
    return () => io.disconnect();
  }, [ioRef]);

  return ioRef;
}

export default useObserver;
