/*eslint-disable*/

import React, { useEffect } from "react";
import "../css/main.css";
import Map from "../components/Map";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useWidth from "../useHooks/useWidth";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";

import $ from "jquery";
import "fullpage.js/vendors/scrolloverflow";
import "fullpage.js";
import "fullpage.js/dist/jquery.fullpage.min.css";

import "react-slideshow-image/dist/styles.css";
import { Fade } from "react-slideshow-image";
import slide01 from "../images/slide01.png";
import slide02 from "../images/slide02.png";
import slide03 from "../images/slide03.png";

// import mainObj from "../images/mainObj.png";
// import Dobj1 from "../images/mainObjD1.png";
// import Dobj2 from "../images/mainObjD2.png";
// import Dobj3 from "../images/mainObjD3.png";

import arrow from "../images/arrow.png";
import circleArrow1 from "../images/circleArrow1.png";
import circleArrow2 from "../images/circleArrow2.png";
import circleArrow3 from "../images/circleArrow3.png";
import mainFlogo from "../images/mainfooter-logo.png";

function Fullpage() {
  const mainSolution = useWidth();

  useEffect(() => {
    if ($("html").hasClass("fp-enabled")) {
      $.fn.fullpage.destroy("all");
    }
    $("#fullpage").fullpage({
      scrollBar: false,
      css: true,
      scrollingSpeed: 700,
      navigation: true,
      loopBottom: true,
    });
  });

  let history = useHistory();

  return (
    <div id="fullpage">
      {/* <div className="section fp-scrollable first">
        <FadeExample /> */}
        {/* <div className="mainBg"></div> */}

        {/* <img src={Dobj1} className="Dobj1" />
        <img src={Dobj2} className="Dobj2" />
        <img src={Dobj3} className="Dobj3" />
        <img src={mainObj} className="mainObj" /> */}

        {/* <h2>
          Data Infrastructure, <br /> <br /> 사람·도시·산업·경제 간 <br />
          데이터를 연결하여 가치를 창출합니다
        </h2> */}
      {/* </div> */}

      <div className="section second">
        <div>
          <h2>
            <span>&nbsp;새로운 가치를 창조하는 기술</span>
            DREAM IMAGINATION
          </h2>

          <div className="contents">
            <div className="vision">
              <p>DI Solution</p>
              <div className="bar"></div>
              <p>
                우리는 <br />
                데이터를 통한 <br />
                새로운 가치를 창조하고 <br />
                시장을 선도합니다.
              </p>
              <a
                onClick={() => {
                  window.location.href = "/company/1";
                }}
              >
                자세히보기
                <img src={arrow} />
              </a>
            </div>
            <div className="value">
              <p>VISION&VALUE</p>
              <div className="bar"></div>
              <p>
                Harmony <br />
                Achievement <br />
                Evolution <br />
              </p>
              <a
                onClick={() => {
                  window.location.href = "/company/2";
                }}
              >
                자세히보기
                <img src={arrow} />
              </a>
            </div>
            <div className="mission">
              <p>HISTORY</p>
              <div className="bar"></div>
              <p>
                DI Solution은 <br />
                고객과 우리의 가치를 위해 <br />
                도전과 성취의 길을 <br />
                걸어갑니다.
              </p>
              <a
                onClick={() => {
                  window.location.href = "/company/3";
                }}
              >
                자세히보기
                <img src={arrow} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="section third">
        <div>
          <h2>
            <span>&nbsp;시장을 선도하는 기술</span>
            OUR SOLUTION
          </h2>

          {mainSolution ? (
            <div className="mContents">
              <Swiper
                modules={[Pagination, A11y, Autoplay]}
                slidesPerView={1.5}
                centeredSlides={true}
                grabCursor={true}
                pagination={{ clickable: true }}
                spaceBetween={30}
                autoplay={{ delay: 2000 }}
                loop={true}
              >
                <SwiperSlide>
                  <div
                    className="mDigitaltwin mItems"
                    onClick={() => {
                      window.location.href = "/solution/1";
                    }}
                  >
                    <p>
                      디지털트윈 안전 관리 플랫폼
                      <span>Digital Twin Safety Management Platform</span>
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="mDeeplearning mItems"
                    onClick={() => {
                      window.location.href = "/solution/2";
                    }}
                  >
                    <p>
                      AI 딥러닝 기반 영상 처리 솔루션
                      <span>
                        AI deep learning based video processing solution
                      </span>
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="mBarrot mItems"
                    onClick={() => {
                      window.location.href = "/solution/3";
                    }}
                  >
                    <p>
                      자동 번역 솔루션
                      <span>Translation solution</span>
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="mPort mItems"
                    onClick={() => {
                      window.location.href = "/solution/4";
                    }}
                  >
                    <p>
                      항만 모니터링 및 분석 시스템
                      <span>Port monitoring&analysis system</span>
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="mWorkflow mItems"
                    onClick={() => {
                      window.location.href = "/solution/5";
                    }}
                  >
                    <p>
                      클라우드형 빅데이터 분석 플랫폼
                      <span>Cloudified big data analytics platform</span>
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="mMonitoring mItems"
                    onClick={() => {
                      window.location.href = "/solution/6";
                    }}
                  >
                    <p>
                      환경 모니터링 시스템
                      <span>Condition monitoring System</span>
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          ) : (
            <div className="contents">
              <div
                className="digitaltwin webDiv"
                onClick={() => {
                  window.location.href = "/solution/1";
                }}
              >
                <div>
                  <p>
                    디지털트윈 안전 관리 플랫폼
                    <span>Digital Twin Safety Management Platform</span>
                  </p>
                  <a>
                    <img src={circleArrow3} />
                  </a>
                </div>
                <div>
                  <p>
                    디지털 트윈 기반의 화재 확산 예측 및 최적 대피 경로 탐색
                    플랫폼
                  </p>
                </div>
              </div>
              <div
                className="deeplearning webDiv"
                onClick={() => {
                  window.location.href = "/solution/2";
                }}
              >
                <div>
                  <p>
                    AI 딥러닝 기반 영상 처리 솔루션
                    <span>
                      AI deep learning based video processing solution
                    </span>
                  </p>
                  <a>
                    <img src={circleArrow3} />
                  </a>
                </div>
                <div>
                  <p>
                    영상내 인식, 식별, 추적을 위한 딥러닝 기반 영상처리 솔루션
                  </p>
                </div>
              </div>
              <div
                className="barrot webDiv"
                onClick={() => {
                  window.location.href = "/solution/3";
                }}
              >
                <div>
                  <p>
                    자동 번역 솔루션
                    <span>Translation solution</span>
                  </p>
                  <a>
                    <img src={circleArrow3} />
                  </a>
                </div>
                <div>
                  <p>
                    딥러닝 기술을 통해 발화자의 음성 인식부터 텍스트 변환(STT),
                    번역(Translation)을 수행하는 자동 번역 솔루션
                  </p>
                </div>
              </div>
              <div
                className="port webDiv"
                onClick={() => {
                  window.location.href = "/solution/4";
                }}
              >
                <div>
                  <p>
                    항만 모니터링 및 분석 시스템
                    <span>Port monitoring&analysis system</span>
                  </p>
                  <a>
                    <img src={circleArrow3} />
                  </a>
                </div>
                <div>
                  <p>
                    터미널 야드 스케줄 관리 및 실시간 운영 모니터링 솔루션
                  </p>
                </div>
              </div>
              <div
                className="workflow webDiv"
                onClick={() => {
                  window.location.href = "/solution/5";
                }}
              >
                <div>
                  <p>
                    클라우드형 빅데이터 분석 플랫폼
                    <span>Cloudified big data analytics platform</span>
                  </p>
                  <a>
                    <img src={circleArrow3} />
                  </a>
                </div>
                <div>
                  <p>
                    간단한 조작만으로 빅데이터를 가공, 분석할 수 있는 클라우드형 분석 플랫폼
                  </p>
                </div>
              </div>
              <div
                className="monitoring webDiv"
                onClick={() => {
                  window.location.href = "/solution/6";
                }}
              >
                <div>
                  <p>
                    환경 모니터링 시스템
                    <span>Condition monitoring System</span>
                  </p>
                  <a>
                    <img src={circleArrow3} />
                  </a>
                </div>
                <div>
                  <p>
                    IoT 센서 데이터 기반의 실시간 환경 모니터링 솔루션
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* <div className="contents">
            
          </div> */}
        </div>
      </div>

      {/* <div className="section forth">
        <div>
          <h2>
            <span>&nbsp;멋있는 멘트</span>
            OUR BUSINESS
          </h2>

          <div className="contents">
            <div
              className="m2m"
              onClick={() => {
                window.location.href = "/business/1";
              }}
            >
              <div className="bg"></div>
              <div className="linkBox">
                <p>
                  Business
                  <br />
                  Information
                </p>
                <span>
                  자세히보기
                  <img src={arrow} />
                </span>
              </div>
            </div>
            <div
              className="installEx"
              onClick={() => {
                window.location.href = "/business/2";
              }}
            >
              <div className="bg"></div>
              <div className="linkBox">
                <p>
                  Installation
                  <br />
                  Example
                </p>
                <span>
                  자세히보기
                  <img src={arrow} />
                </span>
              </div>
            </div>
            <div
              className="product"
              onClick={() => {
                window.location.href = "/business/3";
              }}
            >
              <div className="bg"></div>
              <div className="linkBox">
                <p>
                  Product
                  <br />
                  Information
                </p>
                <span>
                  자세히보기
                  <img src={arrow} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="section fifth">
        <div className="contents">
          <div className="footer-left">
            <h2>CONTACT</h2>
            <div className="circle1"></div>
            <div className="footerCon">
              <h3>(주)디아이솔루션</h3>
              <img src={mainFlogo} alt="logo" />
              <div>
                <p>
                  대표이사<span>남태우</span>
                </p>
                <p>
                  소재지
                  <span>부산광역시 동래구 온천장로 109 불이빌딩 3층</span>
                </p>
                <p>
                  Tel.<span>051-717-2503</span>
                </p>
                <p>
                  Fax.<span>070-8282-0624</span>
                </p>
              </div>
              <p>Copyright © 2022 DI SOLUTION All Rights Reserved.</p>
            </div>
          </div>
          <div className="footer-right">
            <h2>LOCATION</h2>
            <div className="circle1"></div>
            <Map />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fullpage;

const FadeExample = () => {
  const fadeImages = [slide01, slide02, slide03];

  const fadeProperties = {
    pauseOnHover: false,
  };

  return (
    <div>
      <div className="slide-container">
        <Fade {...fadeProperties}>
          <div className="each-fade">
            <div>
              {/* <h2>Dream Imagination</h2> */}
              <img src={fadeImages[0]} />
            </div>
          </div>
          <div className="each-fade">
            <div>
              {/* <h2>Dream Imagination</h2> */}
              <img src={fadeImages[1]} />
            </div>
          </div>
          <div className="each-fade">
            <div>
              {/* <h2>Dream Imagination</h2> */}
              <img src={fadeImages[2]} />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};
