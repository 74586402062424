/*eslint-disable*/

import React from "react";
import Footer from "../components/Footer";

import { Link, Route, Switch } from "react-router-dom";

import "../css/deeplearning.css";
import "../css/responsive.css";
import "swiper/css";
import "swiper/css/bundle";

import Object from "../images/object.png";
import Action from "../images/action.png";
import Face from "../images/face.png";

import MI from "../images/MILogo.png";
import MSS from "../images/MSSLogo.png";
import NIA from "../images/NIALogo.png";
import TTA from "../images/TTALogo.png";

import MIex1 from "../images/MIex1.png";
import MIex2 from "../images/MIex2.png";
import MIex3 from "../images/MIex3.png";
import MSSex1 from "../images/MSS1.png";
import MSSex2 from "../images/MSS2.png";
import NIAex1 from "../images/NIAex1.png";
import NIAex2 from "../images/NIAex2.png";
import TTAex from "../images/TTAex.png";

import Modal from "react-modal";
import { useState } from "react";
import useWidth from "../useHooks/useWidth";
import useObserver from "../useHooks/useObserver";
import SolutionNav from "../components/SolutionNav";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Deeplearning() {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState("");
  const burgerShow = useWidth();
  const ioRef = useObserver();

  function toggleModal(e) {
    setIsOpen(!isOpen);
    if (isOpen) {
      setOpenModal(e.target.id);
    } else {
      setOpenModal("");
    }
  }

  return (
    <div className="deepContainer">
      <div className="deepHeader header" ref={ioRef}>
        <h2>
          AI 딥러닝 기반 영상 처리 솔루션
          <p>
            영상 인식 기술을 통해 최적의 모델을 도출하며, 다양한 사례를 통해
            입증된 역량으로 최상의 서비스를 제공합니다.
          </p>
        </h2>
      </div>

      <SolutionNav />

      <div className="deepCon Contents">
        <p className="pageInfo">
          AI 핵심 기술인 인식 기술을 이용하여 다양한 객체, 행동, 안면 등에 대한{" "}
          <span>탐지 기술</span>을 제공합니다. 다양한 경험을 통해 자체 개발한
          프로그램으로 <span>안정된 서비스</span>가 가능합니다.
        </p>

        <div className="tech">
          <div className="object">
            <img
              src={Object}
              alt="객체인식기술이미지"
              id="Object"
              onClick={toggleModal}
            />
            <Modal
              isOpen={"Object" === openModal}
              onRequestClose={toggleModal}
              contentLabel="useCase"
              style={customStyles}
            >
              <img src={Object} alt="" onClick={toggleModal} />
            </Modal>
            <div>
              <p>객체 인식 기술</p> <br />
              <span>
                객체 인식 모델을 활용하여 사용자 맞춤형 객체 탐지 및 인식 기술을
                제공하며 자동차 번호판 인식 및 탐지 기술도 제공합니다.
              </span>
            </div>
          </div>
          <div className="action">
            <div>
              <p>행동 인식 기술</p> <br />
              <span>
                딥러닝 모델을 통해 여러 행동을 인식하는 기술을 제공하며, 사람의
                속성(상의, 하의 등)에 대한 값을 도출하는 서비스를 제공합니다.
              </span>
            </div>
            <img
              src={Action}
              alt="행동인식기술이미지"
              id="Action"
              onClick={toggleModal}
            />
            <Modal
              isOpen={"Action" === openModal}
              onRequestClose={toggleModal}
              contentLabel="useCase"
              style={customStyles}
            >
              <img src={Action} alt="" onClick={toggleModal} />
            </Modal>
          </div>
          <div className="face">
            <img
              src={Face}
              alt="안면인식기술이미지"
              id="Face"
              onClick={toggleModal}
            />
            <Modal
              isOpen={"Face" === openModal}
              onRequestClose={toggleModal}
              contentLabel="useCase"
              style={customStyles}
            >
              <img src={Face} alt="" onClick={toggleModal} />
            </Modal>
            <div>
              <p>안면 인식 기술</p> <br />
              <span>
                딥러닝 모델을 통해 사람의 안면을 인식하는 기술을 제공하며,
                COVID-19 사태와 같은 감염병에 대비한 안면 마스크 체크 서비스를
                제공합니다
              </span>
            </div>
          </div>
        </div>

        <div className="deepUseCase">
          <div className="deepCaseWrap">
            <h3>참여 사업</h3>

            <div className="caseBoxContainer">
              <div className="caseBox">
                <div>
                  <img src={MI} alt="행안부 로고" />
                </div>
                <p>
                  신종 감염병 해외유입 예측 및 지능적 차단 기술 개발 <br />
                  <span>- 안면 탐지 및 마스크 오착용 탐지</span>
                </p>
                <button id="MIModal" onClick={toggleModal}>
                  상세보기
                </button>
                <Modal
                  isOpen={"MIModal" === openModal}
                  onRequestClose={toggleModal}
                  contentLabel="useCase"
                  style={customStyles}
                >
                  <div className="ModalCon">
                    <img src={MI} alt="행안부 로고" />
                    <p>
                      신종 감염병 해외유입 예측 및 지능적 차단 기술 개발 <br />
                      <span>- 안면 탐지 및 마스크 오착용 탐지</span>
                    </p>
                    <div>
                      <img src={MIex1} alt="" /> <br />
                      <img src={MIex2} alt="" /> <br />
                      <img src={MIex3} alt="" />
                    </div>
                  </div>
                </Modal>
              </div>
              <div className="caseBox">
                <div>
                  <img src={MSS} alt="중소벤처기업부 로고" />
                </div>
                <p>딥러닝 기반 인공지능 기술을 이용한 CCTV 영상 분석</p>
                <button id="MSSModal" onClick={toggleModal}>
                  상세보기
                </button>
                <Modal
                  isOpen={"MSSModal" === openModal}
                  onRequestClose={toggleModal}
                  contentLabel="useCase"
                  style={customStyles}
                >
                  <div className="ModalCon">
                    <img src={MSS} alt="중소벤처기업부 로고" />
                    <p>딥러닝 기반 인공지능 기술을 이용한 CCTV 영상 분석</p>
                    <div>
                      <img src={MSSex1} alt="" /> <br />
                      <img src={MSSex2} alt="" />
                    </div>
                  </div>
                </Modal>
              </div>
              <div className="caseBox">
                <div>
                  <img src={NIA} alt="한국지능정보사회진흥원 로고" /> <br />
                  <img src={TTA} alt="한국정보통신기술협회 로고" />
                </div>
                <p>
                  2021년 인공지능 학습용 데이터 구축 <br />
                  <span>- 공원 주요시설 및 불법행위 감시 CCTV 영상 데이터</span>
                </p>
                <button id="NIAModal" onClick={toggleModal}>
                  상세보기
                </button>
                <Modal
                  isOpen={"NIAModal" === openModal}
                  onRequestClose={toggleModal}
                  contentLabel="useCase"
                  style={customStyles}
                >
                  <div className="ModalCon">
                    <div>
                      <img src={NIA} alt="한국지능정보사회진흥원 로고" />
                      <img src={TTA} alt="한국정보통신기술협회 로고" />
                    </div>
                    <p>
                      2021년 인공지능 학습용 데이터 구축 <br />
                      <span>
                        - 공원 주요시설 및 불법행위 감시 CCTV 영상 데이터
                      </span>
                    </p>
                    <div>
                      <img src={NIAex1} alt="" /> <br />
                      <img src={NIAex2} alt="" />
                    </div>
                  </div>
                </Modal>
              </div>
              <div className="caseBox">
                <div>
                  <img src={NIA} alt="한국지능정보사회진흥원 로고" /> <br />
                  <img src={TTA} alt="한국정보통신기술협회 로고" />
                </div>
                <p>
                  2021년 인공지능 학습용 데이터 구축 <br />
                  <span>- 유동인구 분석을 위한 CCTV 영상 데이터</span>
                </p>
                <button id="TTAModal" onClick={toggleModal}>
                  상세보기
                </button>
                <Modal
                  isOpen={"TTAModal" === openModal}
                  onRequestClose={toggleModal}
                  contentLabel="useCase"
                  style={customStyles}
                >
                  <div className="ModalCon">
                    <div>
                      <img src={NIA} alt="한국지능정보사회진흥원 로고" />
                      <img src={TTA} alt="한국정보통신기술협회 로고" />
                    </div>
                    <p>
                      2021년 인공지능 학습용 데이터 구축 <br />
                      <span>- 유동인구 분석을 위한 CCTV 영상 데이터</span>
                    </p>
                    <div>
                      <img src={TTAex} alt="" />
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        {!burgerShow ? (
          <div className="deeplearningProcess">
            <h3>
              Learning Process
              <div className="line"></div>
            </h3>
            <div className="processWrap">
              <div className="process1 processItem">
                <p>1</p>
                <div>
                  <p>데이터 수집</p>
                  <span className="line"></span>
                  <p>자체 크롤링 프로그램 및 데이터 사업을 통한 데이터 수집</p>
                </div>
              </div>

              <div className="process2 processItem">
                <p>2</p>
                <div>
                  <p>데이터 정제</p>
                  <span className="line"></span>
                  <p>데이터의 중복, 불필요 데이터 삭제 등 데이터 정제</p>
                </div>
              </div>

              <div className="process3 processItem">
                <p>3</p>
                <div>
                  <p>데이터 가공</p>
                  <span className="line"></span>
                  <p>자체 라벨링 프로그램을 통한 데이터 가공</p>
                </div>
              </div>

              <div className="process4 processItem">
                <p>4</p>
                <div>
                  <p>데이터 학습</p>
                  <span className="line"></span>
                  <p>
                    Yolo, YoloR, EfficientNet 등 여러 모델을 활용하여 데이터
                    학습
                  </p>
                </div>
              </div>

              <div className="process5 processItem">
                <p>5</p>
                <div>
                  <p>플랫폼화</p>
                  <span className="line"></span>
                  <p>학습된 모델을 이용하기 위한 플랫폼화</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="MdeeplearningProcess">
            <h3>
              Learning Process
              <div className="line"></div>
            </h3>
            <div className="MprocessWrap">
              <div className="Mprocess1 MprocessItem">
                <p>1</p>
                <div>
                  <p>데이터 수집</p>
                  <span className="line"></span>
                  <p>자체 크롤링 프로그램 및 데이터 사업을 통한 데이터 수집</p>
                </div>
              </div>

              <div className="Mprocess2 MprocessItem">
                <p>2</p>
                <div>
                  <p>데이터 정제</p>
                  <span className="line"></span>
                  <p>데이터의 중복, 불필요 데이터 삭제 등 데이터 정제</p>
                </div>
              </div>

              <div className="Mprocess3 MprocessItem">
                <p>3</p>
                <div>
                  <p>데이터 가공</p>
                  <span className="line"></span>
                  <p>자체 라벨링 프로그램을 통한 데이터 가공</p>
                </div>
              </div>

              <div className="Mprocess4 MprocessItem">
                <p>4</p>
                <div>
                  <p>데이터 학습</p>
                  <span className="line"></span>
                  <p>
                    Yolo, YoloR, EfficientNet 등 여러 모델을 활용하여 데이터
                    학습
                  </p>
                </div>
              </div>

              <div className="Mprocess5 MprocessItem">
                <p>5</p>
                <div>
                  <p>플랫폼화</p>
                  <span className="line"></span>
                  <p>학습된 모델을 이용하기 위한 플랫폼화</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Deeplearning;
