/*eslint-disable*/

import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import "./css/responsive.css";

import { Link, Route, Switch, useLocation } from "react-router-dom";

import Main from "./pages/Main";
import Company from "./pages/Company";
import Solution from "./pages/Solution";
import Contact from "./pages/Contact";
import Viva from "./pages/Viva";
import Info from "./pages/Info";
import His from "./pages/His";
import Digital from "./pages/Digital";
import Deeplearning from "./pages/Deeplearning";
import Barrot from "./pages/Barrot";
import Port from "./pages/Port";
import Workflow from "./pages/Workflow";
import Monitoring from "./pages/Monitoring";
import useWidth from "./useHooks/useWidth";
import TestPage from "./useHooks/useObserver";
import { useRef } from "react";

export const HeaderContext = createContext();

function App() {
  const burgerShow = useWidth();
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [mouseOver, setMouseOver] = useState("company");
  const [mobile, setMobile] = useState();
  const [linkMove, setLinkMove] = useState("/company/1");
  const location = useLocation();
  const [mainNav, setMainNav] = useState(true);
  const headerRef = useRef(null);

  useEffect(() => {
    if (location.pathname === "/") {
      setMainNav(true);
    } else {
      setMainNav(false);
    }
  }, [location]);

  const toggleBurger = () => {
    if (burgerOpen) {
      setBurgerOpen(false);
    } else {
      setBurgerOpen(true);
    }
  };

  const onMouseOver = (e) => {
    setMouseOver("");
    setMouseOver(e.target.innerText.toLowerCase());
  };
  const onClick = (link) => {
    window.location.href = link;
  };
  const onMobileClick = (e, link) => {
    if (link === linkMove) {
      window.location.href = link;
    } else {
      setLinkMove(link);
      setMouseOver(e.target.innerText.toLowerCase());
    }
  };

  window.mobileAndTabletCheck = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  useEffect(() => {
    const result = mobileAndTabletCheck();
    setMobile(result);
  }, [mobile]);

  return (
    <div className="App">
      <header className="pages" ref={headerRef}>
        <div id="headerWrap">
          <h1>
            <a
              onClick={() => {
                window.location.href = "/";
              }}
            ></a>
          </h1>
          {burgerShow ? (
            <>
              <div className="mBurger" onClick={toggleBurger}>
                <div className={burgerOpen ? "showNav" : ""}></div>
                <div className={burgerOpen ? "showNav" : ""}></div>
                <div className={burgerOpen ? "showNav" : ""}></div>
              </div>
              {burgerOpen ? (
                <nav className="mNav">
                  <ul>
                    <li
                      onMouseOver={() => {
                        $(".mDep2").slideUp(250);
                        $(".comDep2").slideDown(250);
                      }}
                    >
                      <a onMouseOver={mobile ? undefined : onMouseOver}>
                        COMPANY<div className="underBar"></div>
                      </a>
                      <ul className="mDep2 comDep2">
                        <li>
                          <a
                            onClick={() => {
                              window.location.href = "/company/1";
                            }}
                          >
                            회사소개
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              window.location.href = "/company/2";
                            }}
                          >
                            비전&핵심가치
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              window.location.href = "/company/3";
                            }}
                          >
                            연혁
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li
                      onMouseOver={() => {
                        $(".mDep2").slideUp(250);
                        $(".solDep2").slideDown(250);
                      }}
                    >
                      <a onMouseOver={mobile ? undefined : onMouseOver}>
                        SOLUTION<div className="underBar"></div>
                      </a>
                      <ul
                        className="mDep2 solDep2"
                      >
                        <li>
                          <a
                            onClick={() => {
                              window.location.href = "/solution/1";
                            }}
                          >
                            디지털트윈 안전 관리 플랫폼
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              window.location.href = "/solution/2";
                            }}
                          >
                            AI 딥러닝 기반 영상 처리 솔루션
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              window.location.href = "/solution/3";
                            }}
                          >
                            자동 번역 솔루션
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              window.location.href = "/solution/4";
                            }}
                          >
                            항만 모니터링 및 분석 시스템
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              window.location.href = "/solution/5";
                            }}
                          >
                            클라우드형 빅데이터 분석 플랫폼
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              window.location.href = "/solution/6";
                            }}
                          >
                            환경 모니터링 시스템
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      {/* <Link to="/contact">CONTACT<div className='underBar'></div></Link> */}
                      <a
                        onMouseOver={mobile ? undefined : onMouseOver}
                        onClick={() => {
                          window.location.href = "/contact";
                        }}
                      >
                        CONTACT<div className="underBar"></div>
                      </a>
                    </li>
                  </ul>
                </nav>
              ) : (
                ""
              )}
            </>
          ) : (
            <nav className="defaultNav">
              <ul id="gnb">
                <li>
                  <a
                    onClick={() => {
                      window.location.href = "/company/1";
                    }}
                  >
                    COMPANY
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      window.location.href = "/solution/1";
                    }}
                  >
                    SOLUTION
                  </a>
                </li>
                <li>
                  {/* <Link to="/contact">CONTACT<div className='underBar'></div></Link> */}
                  <a
                    onClick={() => {
                      window.location.href = "/contact";
                    }}
                  >
                    CONTACT
                  </a>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </header>
      <HeaderContext.Provider value={{ headerRef }}>
        <Switch>
          <Route exact path="/">
            <Main />
          </Route>

          <Route path="/company/1">
            <Company />
            <Info />
          </Route>
          <Route path="/company/2">
            <Company />
            <Viva />
          </Route>
          <Route path="/company/3">
            <Company />
            <His />
          </Route>

          <Route path="/solution/1">
            <Solution />
            <Digital />
          </Route>
          <Route path="/solution/2">
            <Solution />
            <Deeplearning />
          </Route>
          <Route path="/solution/3">
            <Solution />
            <Barrot />
          </Route>
          <Route path="/solution/4">
            <Solution />
            <Port />
          </Route>
          <Route path="/solution/5">
            <Solution />
            <Workflow />
          </Route>
          <Route path="/solution/6">
            <Solution />
            <Monitoring />
          </Route>

          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
      </HeaderContext.Provider>
    </div>
  );
}

export default App;

// function Cursor() {
//   const [cursorX, setCursorX] = useState();
//   const [cursorY, setCursorY] = useState();

//   window.addEventListener('mousemove', (e) => {
//     setCursorX(e.pageX)
//     setCursorY(e.pageY)
//   })

//   return(
//     <div className="cursor"
//       style={{
//       left: cursorX + 'px',
//       top: cursorY + 'px'
//     }}
//     ></div>
//   );
// }
