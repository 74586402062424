/*eslint-disable*/

import React from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";

function CompanyNav() {
  const location = useLocation();
  return (
    <div id="lnb">
      <div>
        <Link
          to="/company/1"
          className={location.pathname === "/company/1" ? "on" : ""}
        >
          회사소개
        </Link>
        <div className={location.pathname === "/company/1" ? "on" : ""} />
      </div>
      <div>
        <Link
          to="/company/2"
          className={location.pathname === "/company/2" ? "on" : ""}
        >
          비전&핵심가치
        </Link>
        <div className={location.pathname === "/company/2" ? "on" : ""} />
      </div>
      <div>
        <Link
          to="/company/3"
          className={location.pathname === "/company/3" ? "on" : ""}
        >
          연혁
        </Link>
        <div className={location.pathname === "/company/3" ? "on" : ""} />
      </div>
    </div>
  );
}

export default CompanyNav;
