/*eslint-disable*/

import React, { useState } from "react";
import tmsImg from "../images/monitoring-1.png";
import tmsImg1 from "../images/tms1.png";
import tmsImg2 from "../images/tms2.png";
import tmsImg3 from "../images/tms3.png";
import tmsImg4 from "../images/tms4.png";
import tmsImg5 from "../images/tms5.png";
import tmsImg6 from "../images/tms6.png";

import "../css/dashboardMonitor.css";
import { useRef } from "react";
import { useEffect } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function DashboardMonitor({ image }) {
  const imgRef = useRef([]);
  const [openModal, setOpenModal] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal(e) {
    setIsOpen(!isOpen);
    if (isOpen) {
      console.log(e.target.id);
      setOpenModal(e.target.id);
    } else {
      setOpenModal("");
    }
  }

  useEffect(() => {
    Object.keys(imgRef.current).map((item) => {
      if (item === image) {
        imgRef.current[item].classList.add("active");
        imgRef.current[item].classList.remove("deactive");
      } else {
        imgRef.current[item].classList.remove("active");
        imgRef.current[item].classList.add("deactive");
      }
    });
  }, [image]);
  return (
    <div>
      <div className="mockupDiv">
        <div
          className="mockupImg"
          ref={(el) => (imgRef.current["image1"] = el)}
        >
          <img src={tmsImg1} id="image1" onClick={toggleModal} />
        </div>
        <div
          className="mockupImg"
          ref={(el) => (imgRef.current["image2"] = el)}
        >
          <img src={tmsImg2} id="image2" onClick={toggleModal} />
        </div>
        <div
          className="mockupImg"
          ref={(el) => (imgRef.current["image3"] = el)}
        >
          <img src={tmsImg3} id="image3" onClick={toggleModal} />
        </div>
        <div
          className="mockupImg"
          ref={(el) => (imgRef.current["image4"] = el)}
        >
          <img src={tmsImg4} id="image4" onClick={toggleModal} />
        </div>
        <div
          className="mockupImg"
          ref={(el) => (imgRef.current["image5"] = el)}
        >
          <img src={tmsImg5} id="image5" onClick={toggleModal} />
        </div>
        <div
          className="mockupImg"
          ref={(el) => (imgRef.current["image6"] = el)}
        >
          <img src={tmsImg6} id="image6" onClick={toggleModal} />
        </div>
      </div>
      <Modal
        isOpen={"image1" === openModal}
        onRequestClose={toggleModal}
        contentLabel="useCase"
        style={customStyles}
      >
        <img src={tmsImg1} alt="" onClick={toggleModal} />
      </Modal>
      <Modal
        isOpen={"image2" === openModal}
        onRequestClose={toggleModal}
        contentLabel="useCase"
        style={customStyles}
      >
        <img src={tmsImg2} alt="" onClick={toggleModal} />
      </Modal>
      <Modal
        isOpen={"image3" === openModal}
        onRequestClose={toggleModal}
        contentLabel="useCase"
        style={customStyles}
      >
        <img src={tmsImg3} alt="" onClick={toggleModal} />
      </Modal>
      <Modal
        isOpen={"image4" === openModal}
        onRequestClose={toggleModal}
        contentLabel="useCase"
        style={customStyles}
      >
        <img src={tmsImg4} alt="" onClick={toggleModal} />
      </Modal>
      <Modal
        isOpen={"image5" === openModal}
        onRequestClose={toggleModal}
        contentLabel="useCase"
        style={customStyles}
      >
        <img src={tmsImg5} alt="" onClick={toggleModal} />
      </Modal>
      <Modal
        isOpen={"image6" === openModal}
        onRequestClose={toggleModal}
        contentLabel="useCase"
        style={customStyles}
      >
        <img src={tmsImg6} alt="" onClick={toggleModal} />
      </Modal>
    </div>
  );
}

export default DashboardMonitor;
