/*eslint-disable*/

import React from "react";
import Footer from "../components/Footer";

import { Link, Route, Switch } from "react-router-dom";

import "../css/port.css";

import Productivity from "../images/Productivity.png";
import Congestion from "../images/Congestion.png";
import Simulation from "../images/Simulation.png";

import Bptmonitoring from "../images/bptMonitoring.png";
import BptLogo from "../images/BPT.png";

import Modal from "react-modal";
import { useState } from "react";
import useObserver from "../useHooks/useObserver";
import SolutionNav from "../components/SolutionNav";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Port() {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState("");
  const ioRef = useObserver();

  function toggleModal(e) {
    setIsOpen(!isOpen);
    if (isOpen) {
      setOpenModal(e.target.id);
    } else {
      setOpenModal("");
    }
  }

  return (
    <div className="portContainer">
      <div className="portHeader header" ref={ioRef}>
        <h2>
          항만 모니터링 및 분석 시스템
          <p>
            항만 컨테이너 터미널의 작업 효율을 높이기 위해 실시간 데이터를
            분석하여 운영에 대한 스케줄 모니터링 서비스를 제공합니다.
          </p>
        </h2>
      </div>
      <SolutionNav />

      <div className="portCon Contents">
        <p className="pageInfo">
          항만 컨테이너 터미널의 정보를 <span>실시간</span>으로{" "}
          <span>모니터링 및 분석</span>하여 선사 - 터미널 간 운영 최적화를
          지원합니다.
        </p>

        <div className="portIcoSec IcoSec">
          <div>
            <img src={Productivity} alt="생산성 분석 icon" />
            <p>생산성 분석</p>
          </div>
          <div>
            <img src={Congestion} alt="혼잡도 분석 icon" />
            <p>혼잡도 분석</p>
          </div>
          <div>
            <img src={Simulation} alt="시뮬레이션 icon" />
            <p>시뮬레이션</p>
          </div>
        </div>

        <div className="portImgSec">
          <div className="imgBox1">
            <div></div>
            <div>
              <p>야드 장비 위치 및 스케줄 모니터링</p>
              <span>
                야드 장비의 실시간 위치 정보를 확인하여 효율적인 스케줄 관리를
                지원합니다.
              </span>
            </div>
          </div>
          <div className="imgBox2">
            <div></div>
            <div>
              <p>야드 블록별 작업 현황 모니터링</p>
              <span>
                야드 블록의 양하, 적하, 반입, 반출 등 블록별 실시간 작업 현황을
                모니터링 합니다.
              </span>
            </div>
          </div>
          <div className="imgBox3">
            <div></div>
            <div>
              <p>컨테이너 적재 블록 모니터링</p>
              <span>
                컨테이너 적재 정보를 시각화하여 한눈에 확인할 수 있습니다.
              </span>
            </div>
          </div>
        </div>

        <div className="portUseCase UseCase">
          <div className="portCaseWrap CaseWrap">
            <h3>Use Case</h3>
            <div className="caseSlide">
              <img src={Bptmonitoring} alt="" onClick={toggleModal} />
            </div>
            <div className="caseItem">
              <div>
                <div className="line"></div>
                <p>Client</p>
              </div>
              <img src={BptLogo} alt="" />
            </div>
            <Modal
              isOpen={isOpen}
              onRequestClose={toggleModal}
              contentLabel="useCase"
              style={customStyles}
            >
              <img src={Bptmonitoring} alt="" onClick={toggleModal} />
            </Modal>
            <div className="caseItem">
              <div>
                <div className="line"></div>
                <p>Detail</p>
              </div>
              <p>
                항만 컨테이너 터미널을 시각화하여 컨테이너 적재 및 선박 양·적하
                현황을 한눈에 확인할 수 있는 최적의 모니터링 서비스를
                제공하였습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Port;
