/*eslint-disable*/

import React, { useState } from "react";
import workflowImg1 from "../images/workflow-1.gif";
import workflowImg2 from "../images/workflow-2.gif";
import workflowImg3 from "../images/workflow-3.gif";

import "../css/dashboardMonitor.css";
import { useRef } from "react";
import { useEffect } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function CloudMonitor({ image }) {
  const imgRef = useRef([]);
  const [openModal, setOpenModal] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal(e) {
    setIsOpen(!isOpen);
    if (isOpen) {
      console.log(e.target.id);
      setOpenModal(e.target.id);
    } else {
      setOpenModal("");
    }
  }

  useEffect(() => {
    Object.keys(imgRef.current).map((item) => {
      if (item === image) {
        imgRef.current[item].classList.add("active");
        imgRef.current[item].classList.remove("deactive");
      } else {
        imgRef.current[item].classList.remove("active");
        imgRef.current[item].classList.add("deactive");
      }
    });
  }, [image]);
  return (
    <div>
      <div className="mockupDiv">
        <div
          className="mockupImg"
          ref={(el) => (imgRef.current["image1"] = el)}
        >
          <img src={workflowImg1} id="image1" onClick={toggleModal} />
        </div>
        <div
          className="mockupImg"
          ref={(el) => (imgRef.current["image2"] = el)}
        >
          <img src={workflowImg2} id="image2" onClick={toggleModal} />
        </div>
        <div
          className="mockupImg"
          ref={(el) => (imgRef.current["image3"] = el)}
        >
          <img src={workflowImg3} id="image3" onClick={toggleModal} />
        </div>
      </div>
      <Modal
        isOpen={"image1" === openModal}
        onRequestClose={toggleModal}
        contentLabel="useCase"
        style={customStyles}
      >
        <img src={workflowImg1} alt="" onClick={toggleModal} />
      </Modal>
      <Modal
        isOpen={"image2" === openModal}
        onRequestClose={toggleModal}
        contentLabel="useCase"
        style={customStyles}
      >
        <img src={workflowImg2} alt="" onClick={toggleModal} />
      </Modal>
      <Modal
        isOpen={"image3" === openModal}
        onRequestClose={toggleModal}
        contentLabel="useCase"
        style={customStyles}
      >
        <img src={workflowImg3} alt="" onClick={toggleModal} />
      </Modal>
    </div>
  );
}

export default CloudMonitor;
