/*eslint-disable*/

import React from "react";
import Footer from "../components/Footer";

import "../css/monitoring.css";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";

import MonitoringIco from "../images/monitoring.png";
import Siren from "../images/siren.png";
import Analysis from "../images/analysis.png";

import DashboardMockup from "../images/dashboardMockup.png";

import Water from "../images/water.png";
import Air from "../images/air.png";
import Greenhouse from "../images/greenhouse.png";
import Soil from "../images/soil.png";
import Waterworks from "../images/waterworks.png";
import EtcMonitoring from "../images/etcMonitoring.png";

import Tms from "../images/tms1.png";
import LXLogo from "../images/LXhausys.png";
import lotte from "../images/waterparkMonitoring.png";
import LWLogo from "../images/Lotte-waterpark.png";
import PM from "../images/pmMonitoring.png";
import BPALogo from "../images/BPA.png";

import Modal from "react-modal";
import { useState } from "react";
import useObserver from "../useHooks/useObserver";
import SolutionNav from "../components/SolutionNav";
import DashboardMonitor from "../components/DashboardMonitor";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";

Modal.setAppElement("#root");

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Monitoring() {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState("");
  const [image, setImage] = useState("image1");
  const ioRef = useObserver();

  function toggleModal(e) {
    setIsOpen(!isOpen);
    if (isOpen) {
      setOpenModal(e.target.id);
    } else {
      setOpenModal("");
    }
  }

  const onClick = (e) => {
    setImage(e.target.id);
  };

  return (
    <div className="monitoringContainer">
      <div className="monitoringHeader header" ref={ioRef}>
        <h2>
          환경 모니터링 시스템
          <p>
            사업장에서 배출되는 각종 오염물질을
            실시간으로 모니터링하고 관리할 수 있는 시스템입니다.
          </p>
        </h2>
      </div>

      <SolutionNav />

      <div className="monitoringCon Contents">
        <p className="pageInfo">
          IoT 기반의 센서 데이터를 <span>실시간</span> 측정하고 이를 관제센터와
          연결하여 365일 24시간 <span>무중단 환경 모니터링</span> 시스템을
          제공합니다.
        </p>

        <div className="monitoringIcoSec IcoSec">
          <div>
            <img src={MonitoringIco} alt="Monitoring icon" />
            <p>
              실시간 모니터링
              <br />
              <span>
                브라우저 접속이 가능한 모든 곳에서
                <br />
                실시간 모니터링이 가능합니다.
              </span>
            </p>
          </div>
          <div>
            <img src={Siren} alt="Siren icon" />
            <p>
              신속한 대응 체계
              <br />
              <span>
                알람 기능을 통해 365일 24시간
                <br />
                신속한 대응 체계를 마련합니다.
              </span>
            </p>
          </div>
          <div>
            <img src={Analysis} alt="Analysis icon" />
            <p>
              데이터 가시화 및 예측
              <br />
              <span>
                데이터 조회 및 파일 다운로드 기능으로
                <br />
                데이터 추세 확인 및 예측이 가능합니다.
              </span>
            </p>
          </div>
        </div>

        <div className="monitoringExam">
          <DashboardMonitor image={image} />
          {/* <img
            src={DashboardMockup}
            alt="대시보드 목업"
            id="DashboardMockup"
            onClick={toggleModal}
          />
          <Modal
            isOpen={"DashboardMockup" === openModal}
            onRequestClose={toggleModal}
            contentLabel="useCase"
            style={customStyles}
          >
            <img src={DashboardMockup} alt="" onClick={toggleModal} />
          </Modal> */}

          <div className="monitoringTabWrap tabWrap">
            <div
              className={image === "image1" ? "on" : ""}
              id="image1"
              onClick={onClick}
            >
              실시간 모니터링
            </div>
            <div
              className={image === "image2" ? "on" : ""}
              onClick={onClick}
              id="image2"
            >
              센서 데이터 이력 관리
            </div>
            <div
              className={image === "image3" ? "on" : ""}
              onClick={onClick}
              id="image3"
            >
              수치 이상 알림
            </div>
            <div
              className={image === "image4" ? "on" : ""}
              onClick={onClick}
              id="image4"
            >
              직전 데이터 비교
            </div>
            <div
              className={image === "image5" ? "on" : ""}
              onClick={onClick}
              id="image5"
            >
              월별 데이터 통계
            </div>
            <div
              className={image === "image6" ? "on" : ""}
              onClick={onClick}
              id="image6"
            >
              배출량 관리
            </div>
          </div>
        </div>

        <div className="monitoringUseExam UseExam">
          <h3>이런 곳에 활용될 수 있습니다</h3>
          <div className="line"></div>
          <div className="monitoringExamWrap ExamWrap">
            <div>
              <p>
                수질 관리
                <br />
                <span>공장 폐수, 수영장 등</span>
              </p>
              <img
                src={Water}
                alt="워터파크 이미지"
                id="Water"
                onClick={toggleModal}
              />
              <Modal
                isOpen={"Water" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Water} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>
                대기오염 관리
                <br />
                <span>미세먼지, 굴뚝 연기 등</span>
              </p>
              <img src={Air} alt="공장 이미지" id="Air" onClick={toggleModal} />
              <Modal
                isOpen={"Air" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Air} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>
                온·습도 관리
                <br />
                <span>비닐하우스 등</span>
              </p>
              <img
                src={Greenhouse}
                alt="비닐하우스 이미지"
                id="Greenhouse"
                onClick={toggleModal}
              />
              <Modal
                isOpen={"Greenhouse" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Greenhouse} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>
                토양오염 관리
                <br />
                <span>원예시설, 재배 시설 등</span>
              </p>
              <img
                src={Soil}
                alt="토양 이미지"
                id="Soil"
                onClick={toggleModal}
              />
              <Modal
                isOpen={"Soil" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Soil} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>
                누수 관리
                <br />
                <span>상수도, 가스 등</span>
              </p>
              <img
                src={Waterworks}
                alt="파이프라인 이미지"
                id="Waterworks"
                onClick={toggleModal}
              />
              <Modal
                isOpen={"Waterworks" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Waterworks} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>기타 센서 데이터 모니터링이 필요한 모든 곳</p>
              <img
                src={EtcMonitoring}
                alt="모니터링 이미지"
                id="EtcMonitoring"
                onClick={toggleModal}
              />
              <Modal
                isOpen={"EtcMonitoring" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={EtcMonitoring} alt="" onClick={toggleModal} />
              </Modal>
            </div>
          </div>
        </div>

        {/* <div className="monitoringUseCase UseCase">
          <div className="monitoringCaseWrap CaseWrap">
            <h3>Use Case</h3>
            <div className="caseSlide">
              <img src={Tms} alt="" onClick={toggleModal} id="Tms" />
            </div>
            <div className="caseItem">
              <div>
                <div className="line"></div>
                <p>Client</p>
              </div>
              <img src={LXLogo} alt="" />
            </div>
            <Modal
              isOpen={"Tms" === openModal}
              onRequestClose={toggleModal}
              contentLabel="useCase"
              style={customStyles}
            >
              <img src={Tms} alt="" onClick={toggleModal} />
            </Modal>
            <div className="caseItem">
              <div>
                <div className="line"></div>
                <p>Detail</p>
              </div>
              <p>
                사업장 굴뚝에서 배출되는 대기 오염 물질을 모니터링할 수 있는
                시스템으로 실시간, 연간 배출량을 관리할 수 있습니다.
              </p>
            </div>
          </div>
        </div> */}
        <div className="monitoringUseCase UseCase">
          <div className="monitoringCaseWrap CaseWrap">
            <h3>Use Case</h3>
            <Swiper
              modules={[Navigation, Pagination, A11y, Autoplay]}
              pagination={{ clickable: true, dynamicBullets: true, }}
              spaceBetween={30}
              autoplay={{ delay: 4500 }}
              loop={true}
            >
              <SwiperSlide>
                <div className="caseSlide">
                  <img src={Tms} alt="" onClick={toggleModal} id="Tms" />
                </div>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Client</p>
                  </div>
                  <img src={LXLogo} alt="" />
                </div>
                <Modal
                  isOpen={"Tms" === openModal}
                  onRequestClose={toggleModal}
                  contentLabel="useCase"
                  style={customStyles}
                >
                  <img src={Tms} alt="" onClick={toggleModal} />
                </Modal>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Detail</p>
                  </div>
                  <p>
                    사업장 굴뚝에서 배출되는 대기 오염 물질을 모니터링할 수 있는
                    시스템으로 실시간, 연간 배출량을 관리할 수 있습니다.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="caseSlide">
                  <img src={lotte} alt="" onClick={toggleModal} id="lotte" />
                </div>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Client</p>
                  </div>
                  <img src={LWLogo} alt="" className="LWLogo" />
                </div>
                <Modal
                  isOpen={"lotte" === openModal}
                  onRequestClose={toggleModal}
                  contentLabel="useCase"
                  style={customStyles}
                >
                  <img src={lotte} alt="" onClick={toggleModal} />
                </Modal>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Detail</p>
                  </div>
                  <p>
                    사업장의 수질오염농도를 실시간으로 자동측정 및 전송하여
                    모니터링 할 수 있는 시스템으로 실시간 수질의 오염 관리를
                    가능하게 합니다.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="caseSlide">
                  <img src={PM} alt="" onClick={toggleModal} id="PM" />
                </div>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Client</p>
                  </div>
                  <img src={BPALogo} alt="" />
                </div>
                <Modal
                  isOpen={"PM" === openModal}
                  onRequestClose={toggleModal}
                  contentLabel="useCase"
                  style={customStyles}
                >
                  <img src={PM} alt="" onClick={toggleModal} />
                </Modal>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Detail</p>
                  </div>
                  <p>
                    전국 미세먼지 현황과 사업장 주변의 미세먼지 현황을 비교하여
                    전국 미세먼지 현황 대비 사업장 인근의 미세먼지 현황을
                    모니터링하여 관리할 수 있습니다.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Monitoring;
