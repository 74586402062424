/*eslint-disable*/

import React from "react";
import Footer from "../components/Footer";

import { Link, Route, Switch } from "react-router-dom";

import "../css/responsive.css";
import "../css/digital.css";

import Siren from "../images/siren.png";
import Monitoring from "../images/monitoring.png";
import Safe from "../images/safe.png";

import DigitalGif from "../images/digitaltwin.gif";

import Hospital from "../images/hospital.png";
import Senior from "../images/senior.png";
import Park from "../images/park.png";
import Safety from "../images/safety.png";

import Pathfinder from "../images/pathfinder.png";
import Saha from "../images/saha.png";
import fire from "../images/dongnaeMonitoring.png";
import dongnae from "../images/dongnae.png";

import Modal from "react-modal";
import { useState } from "react";
import useObserver from "../useHooks/useObserver";
import { useContext } from "react";
import { HeaderContext } from "../App";
import SolutionNav from "../components/SolutionNav";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination, Autoplay } from "swiper";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Digital() {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState("");

  function toggleModal(e) {
    setIsOpen(!isOpen);
    if (isOpen) {
      setOpenModal(e.target.id);
    } else {
      setOpenModal("");
    }
  }

  const ioRef = useObserver();

  return (
    <div className="digitalContainer">
      <div className="digiHeader header" ref={ioRef}>
        <h2>
          디지털트윈 안전 관리 플랫폼
          <p>
            실제 환경과 동일한 가상 환경에서 화재 상황을 시뮬레이션하여 화재
            확산을 예측하고 최적의 대피경로를 탐색할 수 있는 플랫폼입니다.
          </p>
        </h2>
      </div>

      <SolutionNav />

      <div className="digitalCon Contents">
        <p className="pageInfo">
          현실 세계의 사물과 공간을 <span>가상 공간에 디지털화하여</span> 실제와
          동일한 가상 환경에서의 <span>실시간 화재 조기감지 및 대응</span>이
          가능하도록 합니다.
        </p>

        <div className="digitalIcoSec IcoSec">
          <div>
            <img src={Siren} alt="siren icon" />
            <p>
              신속한 대응 체계
              <br />
              <span>
                문제가 발생한 위치를 신속정확하게
                <br />
                파악하여 대응할 수 있습니다.
              </span>
            </p>
          </div>
          <div>
            <img src={Monitoring} alt="Monitoring icon" />
            <p>
              모니터링 솔루션
              <br />
              <span>
                센서 데이터 통합 모니터링으로
                <br />
                오경보, 오감지로 인한 피해를 최소화합니다.
              </span>
            </p>
          </div>
          <div>
            <img src={Safe} alt="Safe icon" />
            <p>
              실시간 안전 관리
              <br />
              <span>
                365일 24시간
                <br />
                안전 관리 서비스를 제공합니다.
              </span>
            </p>
          </div>
        </div>

        <div className="gifSec">
          <img
            src={DigitalGif}
            alt="digital twin info gif"
            onClick={toggleModal}
            id="digitalGif"
          />
          <Modal
            isOpen={"digitalGif" === openModal}
            onRequestClose={toggleModal}
            contentLabel="useCase"
            style={customStyles}
          >
            <img src={DigitalGif} alt="" onClick={toggleModal} />
          </Modal>
        </div>

        <div className="digitalUseExam UseExam">
          <h3>이런 곳에 활용될 수 있습니다</h3>
          <div className="line"></div>
          <div className="digitalExamWrap ExamWrap">
            <div>
              <p>
                병원
                <br />
                <span>낙상 사고 감시</span>
              </p>
              <img
                src={Hospital}
                alt="병원 이미지"
                onClick={toggleModal}
                id="hospital"
              />
              <Modal
                isOpen={"hospital" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Hospital} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>
                노인이용시설
                <br />
                <span>쓰러짐, 이상행동 감시</span>
              </p>
              <img
                src={Senior}
                alt="노인 이미지"
                onClick={toggleModal}
                id="oldManImg"
              />
              <Modal
                isOpen={"oldManImg" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Senior} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>
                공원
                <br />
                <span>화재 감시</span>
              </p>
              <img
                src={Park}
                alt="공원 이미지"
                onClick={toggleModal}
                id="parkImg"
              />
              <Modal
                isOpen={"parkImg" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Park} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>기타 안전 관리가 필요한 모든 곳</p>
              <img
                src={Safety}
                alt="안전모 이미지"
                onClick={toggleModal}
                id="safeHatImg"
              />
              <Modal
                isOpen={"safeHatImg" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Safety} alt="" onClick={toggleModal} />
              </Modal>
            </div>
          </div>
        </div>

        {/* <div className="digitalUseCase UseCase">
          <div className="digitalCaseWrap CaseWrap">
            <h3>Use Case</h3>
            <div className="caseSlide" onClick={toggleModal}>
              <img src={Pathfinder} alt="" id="pathFinderImg" />
              <Modal
                isOpen={"pathFinderImg" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Pathfinder} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div className="caseItem">
              <div>
                <div className="line"></div>
                <p>Client</p>
              </div>
              <img src={Saha} alt="" />
            </div>

            <div className="caseItem">
              <div>
                <div className="line"></div>
                <p>Detail</p>
              </div>
              <p>
                노후화된 사하구청 건물내 화재안전 관리를 위한 디지털트윈 환경
                구축으로 센서 데이터의 실시간 통합 모니터링은 물론 화재 알림,
                확산 예측, 대피경로 탐색이 가능합니다.
              </p>
            </div>
          </div>
        </div> */}

        <div className="digitalUseCase UseCase">
          <div className="digitalCaseWrap CaseWrap">
            <h3>Use Case</h3>
            <Swiper
              modules={[Navigation, Pagination, A11y, Autoplay]}
              pagination={{ clickable: true, dynamicBullets: true }}
              spaceBetween={30}
              autoplay={{ delay: 4500 }}
              loop={true}
            >
              <SwiperSlide>
                <div className="caseSlide">
                  <img
                    src={Pathfinder}
                    alt=""
                    onClick={toggleModal}
                    id="Pathfinder"
                  />
                </div>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Client</p>
                  </div>
                  <img src={Saha} alt="" />
                </div>
                <Modal
                  isOpen={"Pathfinder" === openModal}
                  onRequestClose={toggleModal}
                  contentLabelPathfinder="useCase"
                  style={customStyles}
                >
                  <img src={Pathfinder} alt="" onClick={toggleModal} />
                </Modal>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Detail</p>
                  </div>
                  <p>
                    사업장 굴뚝에서 배출되는 대기 오염 물질을 모니터링할 수 있는
                    시스템으로 실시간, 연간 배출량을 관리할 수 있습니다.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="caseSlide">
                  <img src={fire} alt="" onClick={toggleModal} id="fire" />
                </div>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Client</p>
                  </div>
                  <img src={dongnae} alt="" className="dongnaeLogo" />
                </div>
                <Modal
                  isOpen={"fire" === openModal}
                  onRequestClose={toggleModal}
                  contentLabel="useCase"
                  style={customStyles}
                >
                  <img src={fire} alt="" onClick={toggleModal} />
                </Modal>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Detail</p>
                  </div>
                  <p>
                    CCTV 카메라를 통한 AI 실시간 영상인식으로 산불, 연기를
                    초기에 감지하여 신속한 대응이 가능하도록 합니다.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Digital;
