/*eslint-disable*/

import React from "react";
import Footer from "../components/Footer";

import { Link, Route, Switch } from "react-router-dom";

import "../css/workflow.css";
import "swiper/css";
import "swiper/css/bundle";

import Productivity from "../images/Productivity.png";
import Control from "../images/control.png";
import Visibility from "../images/visibility.png";

import DashboardMockup from "../images/dashboardMockup.png";

import Production from "../images/production.png";
import Retail from "../images/retail.png";
import Edu from "../images/edu.png";
import Farming from "../images/farming.png";
import Restaurant from "../images/restaurant.png";
import EtcData from "../images/etcData.png";

import TMUWorkflow from "../images/workflow.png";
import TMULogo from "../images/TMU.png";

import Modal from "react-modal";
import { useState } from "react";
import useObserver from "../useHooks/useObserver";
import SolutionNav from "../components/SolutionNav";
import CloudMonitor from "../components/CloudMonitor";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination } from "swiper";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Workflow() {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState("");
  const ioRef = useObserver();
  const [image, setImage] = useState("image1");

  function toggleModal(e) {
    setIsOpen(!isOpen);
    if (isOpen) {
      setOpenModal(e.target.id);
    } else {
      setOpenModal("");
    }
  }

  const onClick = (e) => {
    setImage(e.target.id);
  };

  return (
    <div className="workflowContainer">
      <div className="workflowHeader header" ref={ioRef}>
        <h2>
          클라우드형 빅데이터 분석 플랫폼
          <p>
            데이터에 대한 사전 지식없이 간편한 조작만으로 누구나 쉽고 빠르게
            데이터를 가공, 분석할 수 있는 클라우드 환경을 제공합니다.
          </p>
        </h2>
      </div>

      <SolutionNav />

      <div className="workflowCon Contents">
        <p className="pageInfo">
          드래그앤드롭 방식의 <span>간편한 조작</span>으로 데이터를 가공 및
          분석하는 <span>Workflow</span>를 생성하여 사용자 맞춤형 분석 결과를
          도출할 수 있도록 지원합니다.
        </p>

        <div className="workflowIcoSec IcoSec">
          <div>
            <img src={Productivity} alt="Productivity icon" />
            <p>
              생산성 향상
              <br />
              <span>
                언제든지 재사용할 수 있어
                <br />
                생산성이 향상됩니다.
              </span>
            </p>
          </div>
          <div>
            <img src={Control} alt="Control icon" />
            <p>
              간편한 조작
              <br />
              <span>
                데이터에 대한 사전 지식 없이도
                <br />
                Workflow 생성이 가능합니다.
              </span>
            </p>
          </div>
          <div>
            <img src={Visibility} alt="Visibility icon" />
            <p>
              실시간 가시성
              <br />
              <span>
                작업 중 완료된 데이터를
                <br />
                실시간으로 확인할 수 있습니다.
              </span>
            </p>
          </div>
        </div>

        <div className="monitoringExam">
          <CloudMonitor image={image} />
          <Modal
            isOpen={"DashboardMockup" === openModal}
            onRequestClose={toggleModal}
            contentLabel="useCase"
            style={customStyles}
          >
            <img src={DashboardMockup} alt="" onClick={toggleModal} />
          </Modal>

          <div className="workflowTabWrap tabWrap">
            <div
              className={image === "image1" ? "on" : ""}
              id="image1"
              onClick={onClick}
            >
              Workflow 생성
            </div>
            <div
              className={image === "image2" ? "on" : ""}
              id="image2"
              onClick={onClick}
            >
              데이터 가시화
            </div>
            <div
              className={image === "image3" ? "on" : ""}
              id="image3"
              onClick={onClick}
            >
              데이터 분석
            </div>
          </div>
        </div>

        <div className="WorkflowUseExam UseExam">
          <h3>이런 곳에 활용될 수 있습니다</h3>
          <div className="line"></div>
          <div className="WorkflowExamWrap ExamWrap">
            <div>
              <p>
                제조업
                <br />
                <span>설비예지보존, 제품트래킹</span>
              </p>
              <img
                src={Production}
                alt="제조업 이미지"
                id="Production"
                onClick={toggleModal}
              />
              <Modal
                isOpen={"Production" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Production} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>
                소매업
                <br />
                <span>고객 만족도 분석, 매출액 예측</span>
              </p>
              <img
                src={Retail}
                alt="소매업 이미지"
                id="Retail"
                onClick={toggleModal}
              />
              <Modal
                isOpen={"Retail" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Retail} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>
                교육업
                <br />
                <span>성적과 학습행위 사이의 관계 분석</span>
              </p>
              <img
                src={Edu}
                alt="교육업 이미지"
                id="Edu"
                onClick={toggleModal}
              />
              <Modal
                isOpen={"Edu" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Edu} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>
                농업
                <br />
                <span>생산성 향상, 생산 현장 가시화</span>
              </p>
              <img
                src={Farming}
                alt="농업 이미지"
                id="Farming"
                onClick={toggleModal}
              />
              <Modal
                isOpen={"Farming" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Farming} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>
                요식업
                <br />
                <span>방문자수 예측, 재방문 고객 분석</span>
              </p>
              <img
                src={Restaurant}
                alt="요식업 이미지"
                id="Restaurant"
                onClick={toggleModal}
              />
              <Modal
                isOpen={"Restaurant" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={Restaurant} alt="" onClick={toggleModal} />
              </Modal>
            </div>
            <div>
              <p>기타 데이터 분석이 필요한 모든 곳</p>
              <img
                src={EtcData}
                alt="데이터분석 이미지"
                id="EtcData"
                onClick={toggleModal}
              />
              <Modal
                isOpen={"EtcData" === openModal}
                onRequestClose={toggleModal}
                contentLabel="useCase"
                style={customStyles}
              >
                <img src={EtcData} alt="" onClick={toggleModal} />
              </Modal>
            </div>
          </div>
        </div>

        <div className="workFlowUseCase UseCase">
          <div className="workFlowCaseWrap CaseWrap">
            <h3>Use Case</h3>
            <div className="caseSlide">
              <img
                src={TMUWorkflow}
                alt=""
                id="TMUWorkflow"
                onClick={toggleModal}
              />
            </div>
            <div className="caseItem">
              <div>
                <div className="line"></div>
                <p>Client</p>
              </div>
              <img src={TMULogo} alt="" />
            </div>
            <Modal
              isOpen={"TMUWorkflow" === openModal}
              onRequestClose={toggleModal}
              contentLabel="useCase"
              style={customStyles}
            >
              <img src={TMUWorkflow} alt="" onClick={toggleModal} />
            </Modal>
            <div className="caseItem">
              <div>
                <div className="line"></div>
                <p>Detail</p>
              </div>
              <p>
                학사 데이터 분석 환경 제공 및 데이터 활용도를 향상함으로써
                데이터 중심의 대학 운영 관리 체계를 구축하였습니다.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="workFlowUseCase UseCase">
          <div className="workFlowCaseWrap CaseWrap">
            <h3>Use Case</h3>
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              navigation
            >
              <SwiperSlide>
                <div className="caseSlide">
                  <img
                    src={TMUWorkflow}
                    alt=""
                    id="TMUWorkflow"
                    onClick={toggleModal}
                  />
                </div>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Client</p>
                  </div>
                  <img src={TMULogo} alt="" />
                </div>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Detail</p>
                  </div>
                  <p>
                    학사 데이터 분석 환경 제공 및 데이터 활용도를 향상함으로써
                    데이터 중심의 대학 운영 관리 체계를 구축하였습니다.
                  </p>
                </div>
                <Modal
                  isOpen={"TMUWorkflow" === openModal}
                  onRequestClose={toggleModal}
                  contentLabel="useCase"
                  style={customStyles}
                >
                  <img src={TMUWorkflow} alt="" onClick={toggleModal} />
                </Modal>
              </SwiperSlide>
              <SwiperSlide>
                <div className="caseSlide">
                  <img
                    src={TMUWorkflow}
                    alt=""
                    id="TMUWorkflow"
                    onClick={toggleModal}
                  />
                </div>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Client</p>
                  </div>
                  <img src={TMULogo} alt="" />
                </div>
                <div className="caseItem">
                  <div>
                    <div className="line"></div>
                    <p>Detail</p>
                  </div>
                  <p>
                    학사 데이터 분석 환경 제공 및 데이터 활용도를 향상함으로써
                    데이터 중심의 대학 운영 관리 체계를 구축하였습니다.
                  </p>
                </div>
                <Modal
                  isOpen={"TMUWorkflow" === openModal}
                  onRequestClose={toggleModal}
                  contentLabel="useCase"
                  style={customStyles}
                >
                  <img src={TMUWorkflow} alt="" onClick={toggleModal} />
                </Modal>
              </SwiperSlide>
              <SwiperSlide>Slide 3</SwiperSlide>
              <SwiperSlide>Slide 4</SwiperSlide>
            </Swiper>
          </div>
        </div> */}
      </div>

      <Footer />
    </div>
  );
}

export default Workflow;
