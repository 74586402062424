/*eslint-disable*/

import React, { useRef } from "react";
import "../css/contact.css";

import emailjs from "@emailjs/browser";
import Map from "../components/Map";

import Pin from "../images/location.png";
import Call from "../images/call.png";

import Footer from "../components/Footer";

import $ from "jquery";
import useObserver from "../useHooks/useObserver";
import { useState } from "react";

function Contact() {
  const ioRef = useObserver();
  const [toast, setToast] = useState(false);

  window.addEventListener("scroll", function () {
    this.scrollY > 200
      ? $(".topBtn").addClass("on")
      : $(".topBtn").removeClass("on");
  });

  const moveTop = () => {
    $("html, body").animate({ scrollTop: 0 });
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_t0nbzj9",
        "template_ycsjzbu",
        form.current,
        "user_BVUdObmqMT09wD1BxfQmJ"
      )
      .then(
        (result) => {
          // console.log(result.text);
          document.getElementById("contactForm").reset();
        },
        (error) => {
          // console.log(error.text);
        }
    );
    
    setToast(true);
    setTimeout(() => {
      setToast(false);
    }, 1500);
  };

  return (
    <div className="conContainer">
      {toast ? <ToastModal /> : ""}
      <div className="conTop" ref={ioRef}>
        CONTACT
      </div>
      <div className="conContents">
        <div className="location">
          <h2>LOCATION</h2>
          <div>
            <Map />
            <div>
              <img src={Pin} alt="pinIcon" />
              <p>부산광역시 동래구 온천장로 109 불이빌딩 3층</p>
            </div>
            <div>
              <img src={Call} alt="pinIcon" />
              <p>051-717-2503</p>
            </div>
          </div>
        </div>
        <div className="conForm">
          <h2>CONTACT</h2>
          <form ref={form} onSubmit={sendEmail} id="contactForm">
            <div>
              {/* <label>Name</label> */}
              <input
                className="reset"
                type="text"
                name="user_name"
                placeholder="Your name"
                required
              />
            </div>
            <div>
              {/* <label>Email</label> */}
              <input
                type="email"
                name="user_email"
                placeholder="example@email.com"
                required
              />
            </div>
            <div>
              {/* <label>Message</label> */}
              <textarea name="message" placeholder="Message" required />
            </div>
            <input type="submit" value="Send" />
          </form>
        </div>
      </div>

      <div className="topBtn" onClick={moveTop}></div>
      <Footer />
    </div>
  );
}

export default Contact;

function ToastModal() {
  return (
    <div className="toastBox">
      메일이 발송되었습니다.
    </div>
  )
}